<template>
  <HostLayoutContainer class="client">
    <HostLayoutItem>
      <div class="core__title" id="core">核心功能</div>
      <div class="subhead">全面解决多云/异构问题</div>
      <div style="width: 1000px; margin: 0 auto">
        <img src="/assets/product/product-core.png" alt="">
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>

<script>
import { defineComponent } from 'vue'
import CardsRow from '@/components/cards/cards-row.vue'
import Row from '@/components/cards/row.vue'
import Item from '@/components/cards/item.vue'
import Card4 from '@/components/cards/card4.vue'
export default defineComponent({
  name: 'Client',
  components: {
    CardsRow,
    Row,
    Item,
    Card4,
  }
})
</script>

<style lang="scss" scoped>
.core__title {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D253E;
  line-height: 45px;
  letter-spacing: 2px;
}
.subhead{
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D253E;
  line-height: 45px;
  letter-spacing: 2px;
}

.matrix__row {
  display: flex;
  justify-content: space-around;
}
</style>
