<template>
  <Banner />
  <PlatformCore />
  <CustomerValue />
  <ProductFrame />
  <ProductFunc />
  <Footer />
  <CopyRight />
</template>

<script>
import Banner from '@/components/banner.vue'
import PlatformCore from '@/components/product/multi-manage-platform/product-core.vue'
import CustomerValue from '@/components/product/multi-manage-platform/customer-value.vue'
import ProductFrame from '@/components/product/multi-manage-platform/product-frame.vue'
import ProductFunc from '@/components/product/multi-manage-platform/product-func.vue'
import Footer from '@/components/footer.vue'
import CopyRight from '@/components/copy-right.vue'

export default {
  name: 'Home',
  components: {
    Banner,
    PlatformCore,
    CustomerValue,
    ProductFrame,
    ProductFunc,
    CopyRight,
    Footer
  }
}
</script>
