<template>
  <div class="footer">
    <div class="footer__inner">
      <div>
        © Copyright 2021 - {{ year }} 北京云易智科技有限公司 网站备案/许可证号：
        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2022005295号</a >
      </div>
      <div>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  setup() {
    return {
      year: (new Date()).getFullYear()
    }
  }
})
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #eee;

  padding-top: 15px;
  padding-bottom: 15px;

  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: rgba(122, 122, 122, 0.65);
}
.footer__inner {
  text-align: center;
}
.split {
  margin-left: 15px;
  margin-right: 15px;
}
</style>
