<template>
  <div class="footer">
    <div class="footer__main">
      <div class="layout__container--width">
        <div class="footer__logo">
          <div class="footer__logo-image">
            <img src="/assets/home/logo_introduce@3x.svg" alt="" />
          </div>
          <div class="footer__logo-text">企业用云的有力帮手</div>
        </div>
        <div class="footer__links">
          <div
            v-for="(group, index) in data"
            :key="index"
            class="footer__link-group"
          >
            <div class="footer__link-group-title" v-text="group.text" />
            <div
              class="footer__link"
              v-for="(link, linkIndex) in group.children"
              :key="linkIndex"
            >
              <router-link :to="link.href" v-text="link.text"></router-link>
            </div>
          </div>
        </div>
        <!-- <div class="footer__erweima">
          <div class="footer__erweima-title">关注我们</div>
          <div class="footer__erweima-image">
            <img src="/assets/images/public/footer_erweima@3x.png" alt="" />
          </div>
        </div> -->
      </div>
    </div>

    <!--<div class="footer__copyright">
      <div class="footer__copyright-text">北京易云服技术有限公司  Copyright © 京ICP备2021005055号</div>
    </div>-->
  </div>
</template>

<script>
import { data } from '@/configs/footer'
export default {
  name: 'Footer',
  data () {
    return {
      data,
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  border: 1px solid #EEEEEE;
}
.footer__main {
  height: 308px;
}
.footer .layout__container--width {
  display: flex;
}
.layout__container--width {
    margin: 0 auto;
    width: 1200px;
}
.footer__logo {
  margin-top: 40px;
  width: 166px;
}
.footer__logo-image {
  width: 173px;
  height: 65px;
}
.footer__logo-text {
  margin-top: 129px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #3A4B70;
  line-height: 22px;

  white-space: nowrap;
}
.footer__links {
  margin-top: 63px;
  margin-left: 175px;
  display: flex;
  flex: 1;
}
.footer__link-group {
  width: 160px;
}
.footer__link-group-title {
  margin-bottom: 21px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3A4B70;
  line-height: 20px;
  letter-spacing: 1px;
}
a {
  color: inherit;
  text-decoration: none;
}
.footer__link {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3A4B70;
  line-height: 37px;
}

.footer__erweima {
  margin-top: 62px;
  width: 120px;
  text-align: center;
}

.footer__erweima-title {
  margin-bottom: 24px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 20px;
  letter-spacing: 1px;
}

.footer__erweima-image {
  width: 100%;
  height: auto;
}
.footer__copyright {
  text-align: center;
}
.footer__copyright-text {
  display: inline-block;
  width: 383px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #E0E6FF;
  line-height: 40px;
  letter-spacing: 1px;
}

</style>
