export const data = [
  {
    type: 'group',
    text: '首页',
    children: [
      {text: '公司介绍', href: '/ezsmartcloud/home#introduce'},
      {text: '我们的客户', href: '/ezsmartcloud/home#client'},
      {text: '安全合规', href: '/ezsmartcloud/home#certification'},
    ]
  },
  {
    type: 'group',
    text: 'Microsoft 产品',
    children: [
      {text: '认识 Azure', href: '/ezsmartcloud/microsoft/#azure-introduce'},
      {text: '安全可信', href: '/ezsmartcloud/microsoft/#security-believe'},
      {text: '遍布全球', href: '/ezsmartcloud/microsoft/#global'},
      {text: '全球和中国', href: '/ezsmartcloud/microsoft/#world-and-china'},
      {text: '其他产品', href: '/ezsmartcloud/microsoft/#other-product'},
    ]
  },
  {
    type: 'group',
    text: '多云管理平台',
    children: [
      {text: '核心功能', href: '/ezsmartcloud/multi-manage-platform/#core'},
      {text: '客户价值', href: '/ezsmartcloud/multi-manage-platform/#customer-value'},
      {text: '产品架构', href: '/ezsmartcloud/multi-manage-platform/#framework'},
      {text: '功能概览', href: '/ezsmartcloud/multi-manage-platform/#overview'},
    ]
  },
  {
    type: 'group',
    text: '云服务',
    children: [
      {text: '云管理服务', href: '/ezsmartcloud/service#resoure-administration'},

    ]
  },
  {
    type: 'group',
    text: '解决方案',
    children: [
      {text: '混合云构建', href: '/ezsmartcloud/solution#solution-mixture'},
      {text: '全新现代化办公', href: '/ezsmartcloud/solution#solution-modernization'},
      {text: '云管理平台', href: '/ezsmartcloud/solution#solution-cloud-platform'},
      {text: '客户成功案例', href: '/ezsmartcloud/solution#success-case'},
    ]
  },
  {
    type: 'group',
    text: '关于我们',
    children: [
      {text: '加入我们', href: '/ezsmartcloud/about#join-us'},
      {text: '关于我们', href: '/ezsmartcloud/about#about'},
      {text: '联系我们', href: '/ezsmartcloud/about#contact'},
    ]
  },
]
